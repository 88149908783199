import { useCallback } from 'react';

import { ConversionEventType } from 'types/enums/ConversionEvent';
import { PartnerType } from 'types/enums/PartnerType';
import { PostbackType } from 'types/enums/PostbackType';

import { ConversionsApi } from 'api/ConversionsApi';
import { FacebookPixel } from 'helpers/analytics/fbPixel';
import { Gtm } from 'helpers/analytics/gtm';

//  ! do not use useEffect here
export const useConversionTracking = () => {
  const sendFBConversion = useCallback((event: any & { isRetry: boolean }) => {
    const conversionPixelId = event?.data?.pixel_id;
    const eventID = event?.data?.event_id;
    const isRetry = event?.isRetry;

    // ? disable tracking for iframe)
    if (window.location !== window.parent?.location) return;

    if (!conversionPixelId || !eventID) return;

    if (!window.fbqInitialized) {
      FacebookPixel.init({
        newPixelId: conversionPixelId,
      });

      window.fbqInitialized = true;
    }

    FacebookPixel.trackEvent({ eventData: event.data, isRetry });
  }, []);

  const sendGTMConversion = useCallback((event: any) => {
    // ? disable tracking for iframe)
    if (window.location !== window.parent?.location) return;

    Gtm.trackConversionEventByName(event.data);
  }, []);

  const conversionEventHandler = useCallback(
    (event: { data: any }) => {
      if (event?.data?.partner_type === PartnerType.Facebook) {
        sendFBConversion(event);

        return;
      }

      if (event?.data?.partner_type === PartnerType.GoogleAds) {
        sendGTMConversion(event);
      }
    },
    [sendFBConversion, sendGTMConversion]
  );

  const sendSingleFacebookConversionHandler = useCallback(
    async ({
      eventType,
      isRetry,
    }: {
      eventType: ConversionEventType;
      isRetry?: boolean;
    }) => {
      const eventData = await ConversionsApi.createConversionData(eventType);

      if (eventData.postback_type !== PostbackType.Facebook) return;

      sendFBConversion({ data: eventData, isRetry: isRetry || false });
    },
    [sendFBConversion]
  );

  const sendSingleGtmConversionHandler = useCallback(
    async ({
      eventType,
      isRetry,
    }: {
      eventType: ConversionEventType;
      isRetry?: boolean;
    }) => {
      const eventData = await ConversionsApi.createConversionData(eventType);

      if (eventData.postback_type !== PostbackType.GoogleAds) return;

      Gtm.trackConversionEventByType({
        eventType,
        isRetry: isRetry || false,
      });
    },
    []
  );

  return {
    conversionEventHandler,
    sendSingleFacebookConversionHandler,
    sendSingleGtmConversionHandler,
  };
};
