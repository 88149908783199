import ReactPixel from 'react-facebook-pixel';

import {
  ConversionEventType,
  FacebookConversionEventName,
} from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';

export const FacebookPixel = {
  init({ newPixelId }: { newPixelId: string | number }) {
    ReactPixel.init(String(newPixelId));
    ReactPixel.pageView();
  },

  mapEventNameToEventType(eventName: FacebookConversionEventName) {
    switch (eventName) {
      case FacebookConversionEventName.AddToCart: {
        return ConversionEventType.PhotoUpload;
      }
      case FacebookConversionEventName.AddToWishlist: {
        return ConversionEventType.MailConfirm;
      }
      case FacebookConversionEventName.CompleteRegistration: {
        return ConversionEventType.Registration;
      }
      case FacebookConversionEventName.Purchase: {
        return ConversionEventType.FirstPurchase;
      }
      case FacebookConversionEventName.PurchaseTotal: {
        return ConversionEventType.PurchaseTotal;
      }
      case FacebookConversionEventName.UserSpent1cr: {
        return ConversionEventType.UserSpent1cr;
      }
      case FacebookConversionEventName.UserSpent5cr: {
        return ConversionEventType.UserSpent5cr;
      }
      case FacebookConversionEventName.UserSpent10cr: {
        return ConversionEventType.UserSpent10cr;
      }
      case FacebookConversionEventName.UserSpent15cr: {
        return ConversionEventType.UserSpent15cr;
      }
      case FacebookConversionEventName.UserSpent20cr: {
        return ConversionEventType.UserSpent20cr;
      }
      case FacebookConversionEventName.FindLocation: {
        return ConversionEventType.FindLocation;
      }
      case FacebookConversionEventName.EngagedUserV1: {
        return ConversionEventType.EngagedUserV1;
      }
      case FacebookConversionEventName.EU30min: {
        return ConversionEventType.EU30min;
      }
      case FacebookConversionEventName.EUSubmit: {
        return ConversionEventType.EUSubmit;
      }
      case FacebookConversionEventName.EURetention: {
        return ConversionEventType.EURetention;
      }

      default:
        return null;
    }
  },

  trackEvent(payload: { eventData: any; isRetry: boolean }) {
    const eventName = payload.eventData?.event;
    const eventID = payload.eventData?.event_id;

    const userData = payload.eventData?.user_data;
    const customData = payload.eventData?.custom_data;

    if (!Object.values(FacebookConversionEventName).includes(eventName)) return;

    const pixelCustomParams = { ...userData, ...customData };
    const eventType = this.mapEventNameToEventType(eventName);

    ReactPixel.fbq('track', eventName, pixelCustomParams, { eventID });

    if (eventType)
      ConversionsApi.forceServerConversion({
        eventType,
        isRetry: payload.isRetry,
      });
  },
};
