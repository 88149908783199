import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';

import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { notifyRouteLoadEvents } from 'helpers/routeEvents';
import { useMedia } from 'hooks/useMedia';

import { FullscreenSpinner } from '../FullscreenSpinner';

interface IProps<T> {
  component: React.ComponentType<T> | LoadableComponent<T>;
  componentProps?: T;
  isRestrictedDevice?: boolean;
  restrictedType?: RoutesRestrictionType;
}

export const AppRoute = <T extends object>({
  component: Component,
  componentProps = {} as T,
  restrictedType,
}: IProps<T>) => {
  const { pathname } = useLocation();

  const { isMobile, isTablet, isDesktop } = useMedia();

  useEffect(() => {
    notifyRouteLoadEvents(Component, pathname);
  }, [Component, pathname]);

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.MobileRestricted &&
    isMobile
  ) {
    return <Navigate to="/finder" replace />;
  }

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.DesktopRestricted &&
    (isDesktop || isTablet)
  ) {
    return <Navigate to="/finder" replace />;
  }

  return (
    <Component {...componentProps} fallback={<FullscreenSpinner fallback />} />
  );
};
