import { useCallback, useEffect, useRef, useState } from 'react';

import { ConversionEventType } from 'types/enums/ConversionEvent';

import { ConversionQueue, ConversionsApi } from 'api/ConversionsApi';

import { useConversionTracking } from './useConversionTracking';

const CONVERSIONS_TO_RESEND = [
  ConversionEventType.EngagedUserV1,
  ConversionEventType.EURetention,
  ConversionEventType.EUSubmit,
  ConversionEventType.EU30min,
];

export const useRetryConversions = (isAuthenticated: boolean) => {
  const trackedEvents = useRef<ConversionQueue[]>([]);

  const {
    sendSingleFacebookConversionHandler,
    sendSingleGtmConversionHandler,
  } = useConversionTracking();
  const [conversionsQueue, setConversionsQueue] = useState<ConversionQueue[]>(
    []
  );

  const loadConversionsQueue = useCallback(async () => {
    const conversionsQueueResponse = await ConversionsApi.getConversionsQueue();
    const filteredConversions = conversionsQueueResponse.filter(
      (conversionItem) =>
        CONVERSIONS_TO_RESEND.includes(conversionItem.event_type)
    );

    setConversionsQueue(filteredConversions);
  }, []);

  useEffect(() => {
    if (isAuthenticated) loadConversionsQueue();
  }, [isAuthenticated, loadConversionsQueue]);

  useEffect(() => {
    if (conversionsQueue.length === trackedEvents.current.length) return;

    conversionsQueue.forEach((queueItem) => {
      if (
        trackedEvents.current.find(
          (trackedEvent) =>
            trackedEvent.event_type === queueItem.event_type &&
            trackedEvent.partner_type === queueItem.partner_type
        )
      )
        return;

      const eventType = queueItem.event_type;

      if (!eventType) return;

      if (queueItem.partner_type === 'FACEBOOK') {
        sendSingleFacebookConversionHandler({
          eventType,
          isRetry: true,
        });
      }

      if (queueItem.partner_type === 'GTM') {
        sendSingleGtmConversionHandler({ eventType, isRetry: true });
      }

      trackedEvents.current.push(queueItem);
    });
  }, [
    conversionsQueue,
    sendSingleFacebookConversionHandler,
    sendSingleGtmConversionHandler,
  ]);
};
