import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UnreadCounters } from 'types/interfaces/UnreadCounters';

import {
  getIsConfirmationNotificationShown,
  setIsConfirmationNotificationShown,
} from 'helpers/popups/emailConfirmation';

interface IFeatureFlags {
  isEnabledNotificationsSound: boolean;
  isEnabledChatSayHelloInit: boolean;
  isEnabledIntentForm: boolean;
  isEnabledWebViewRedirect: boolean;
  isEnabledPricing: boolean;
  isEnabledPrivateFeatures: boolean;
  isEnabledFreeReading: boolean;
}

interface ITranslations {
  isEnabled: boolean;
  buildVersion: null | string;
}

interface ICommonState {
  loading: boolean;
  unreadCount: UnreadCounters;
  prevPagePathname: string;
  currentLang: string;
  supportedLangs: string[];
  translations: ITranslations;
  featureFlags: IFeatureFlags;
  isWizardFinished: boolean;

  popups: {
    confirmMailFromNotificationPopup: boolean;
  };
}

const initialState: ICommonState = {
  loading: true,
  unreadCount: {
    dialogs: 0,
    notifications: 0,
    inmails: 0,
    chat_requests: 0,
  },
  prevPagePathname: '',
  currentLang: 'en',
  supportedLangs: [],
  translations: {
    isEnabled: false,
    buildVersion: null,
  },
  featureFlags: {
    isEnabledNotificationsSound: false,
    isEnabledChatSayHelloInit: false,
    isEnabledIntentForm: false,
    isEnabledWebViewRedirect: false,
    isEnabledPricing: false,
    isEnabledPrivateFeatures: false,
    isEnabledFreeReading: false,
  },
  isWizardFinished: false,

  // TODO move to system popups
  popups: {
    confirmMailFromNotificationPopup:
      getIsConfirmationNotificationShown() || false,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setInitState(
      state,
      action: PayloadAction<
        Omit<
          ICommonState,
          'prevPagePathname' | 'initializedPixelsIds' | 'popups'
        >
      >
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },

    resetState() {
      return initialState;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    updateUnreadCounters(state, action: PayloadAction<UnreadCounters>) {
      state.unreadCount = action.payload;
    },

    setPrevPagePathname(state, action: PayloadAction<string>) {
      state.prevPagePathname = action.payload;
    },

    setCurrentLang(state, action: PayloadAction<string>) {
      state.currentLang = action.payload;
    },

    setIsWizardFinished(state, action: PayloadAction<boolean>) {
      state.isWizardFinished = action.payload;
    },

    setIsEnabledNotificationsSound(state, action: PayloadAction<boolean>) {
      state.featureFlags.isEnabledNotificationsSound = action.payload;
    },

    closeConfirmMailFromNotificationPopup(state) {
      state.popups.confirmMailFromNotificationPopup = true;

      setIsConfirmationNotificationShown();
    },
  },
});

export const {
  setInitState,
  resetState,
  setLoading,
  updateUnreadCounters,
  setPrevPagePathname,
  setCurrentLang,
  setIsEnabledNotificationsSound,
  setIsWizardFinished,

  closeConfirmMailFromNotificationPopup,
} = commonSlice.actions;

export const common = commonSlice.reducer;
