import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'App';
import { GlobalErrorBoundary } from 'GlobalErrorBoundary';
import { store } from 'store';

import { PWAContextProvider } from 'contexts/PWAContext';
import { ToastsProvider } from 'contexts/ToastsContext';
import { MirrorService } from 'services/MirrorService';

import { WebSocketProvider } from 'components/shared/WebSocketProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const appleTouchIconUrl = `/favicons/${MirrorService.siteName}-192x192.png`;

export const AppContainer = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="57x57" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="114x114" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="72x72" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="144x144" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="60x60" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="120x120" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="76x76" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" sizes="152x152" href={appleTouchIconUrl} />
        <link rel="apple-touch-icon" href={appleTouchIconUrl} />
      </Helmet>
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: false,
        }}
      >
        <GlobalErrorBoundary>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ToastsProvider>
                <WebSocketProvider>
                  <PWAContextProvider>
                    <App />
                  </PWAContextProvider>
                </WebSocketProvider>
              </ToastsProvider>
            </QueryClientProvider>
          </Provider>
        </GlobalErrorBoundary>
      </BrowserRouter>
    </HelmetProvider>
  );
};
