import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

import {
  getDefaultSiteApiUrl,
  getEnvByHostname,
  getServiceApiUrl,
  getSiteApiUrl,
  getSiteConfigByHostname,
  getSiteWsUrl,
  getTranslationsBaseUrl,
} from './utils';

export class AppConfig {
  env: AppEnv;

  id: number;

  groupId: number;

  domain: AppDomain;

  siteDomainWithoutSubdomain: string;

  apiUrl: string;

  wsUrl: string;

  originForInitialId: string;

  originForTranslations: string;

  originForServiceDomain: string;

  gtmId: string;

  supportEmail: string;

  supportPhone: string;

  sitename: string;

  descriptor: string;

  cookieCbid: string;

  clarityId: string;

  constructor(hostname: string) {
    const env = getEnvByHostname(hostname);
    const siteConfig = getSiteConfigByHostname(hostname);
    const siteId = siteConfig.id;
    const siteGroupId = siteConfig.groupId;
    const siteDomain = siteConfig.domain;
    const siteDomainWithoutSubdomain = hostname.split('.').slice(-2).join('.');

    const siteName =
      siteConfig.sitename || siteDomain.replace(/\.(club|com)$/, '');
    const siteSupportEmail = `support@${siteDomain}`;
    const siteSupportPhone = siteConfig.supportPhone || '';
    const gtm = siteConfig.gtm?.[env] || '';
    const siteDescriptor = siteConfig.descriptor || '';
    const cookieCbid = siteConfig.cookieCbid?.[env] || '';
    const clarityId = siteConfig.clarityId?.[env] || '';

    this.env = env;
    this.id = siteId;
    this.groupId = siteGroupId;
    this.apiUrl = getSiteApiUrl(hostname);
    this.wsUrl = getSiteWsUrl(hostname);
    this.originForInitialId = getDefaultSiteApiUrl(hostname);
    this.originForServiceDomain = getServiceApiUrl(hostname);
    this.originForTranslations = getTranslationsBaseUrl(hostname);
    this.domain = siteDomain;
    this.siteDomainWithoutSubdomain = siteDomainWithoutSubdomain;
    this.gtmId = gtm;
    this.supportEmail = siteSupportEmail;
    this.sitename = siteName;
    this.supportPhone = siteSupportPhone;
    this.descriptor = siteDescriptor;
    this.cookieCbid = cookieCbid;
    this.clarityId = clarityId;
  }
}
