import { MirrorService } from 'services/MirrorService';
import {
  ConversionEventType,
  GtmConversionEventName,
} from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';

export const Gtm = {
  init(params: { gtmId: string }) {
    const { gtmId } = params;

    if (!gtmId) {
      return;
    }

    const innerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    const noscriptInner = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    script.innerHTML = innerScript;
    noscript.innerHTML = noscriptInner;
  },

  sendEvent(payload: { eventName: GtmConversionEventName; isRetry: boolean }) {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer?.push({ event: payload.eventName });

    const eventType = this.mapEventNameToEventType(payload.eventName);

    if (eventType)
      ConversionsApi.forceServerConversion({
        isRetry: payload.isRetry,
        eventType,
      });
  },

  userRegEvent(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.Registration, isRetry });
  },

  userMailConfirm(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.MailConfirm, isRetry });
  },

  userSale(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.Sale, isRetry });
  },

  userResale(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.Resale, isRetry });
  },

  userCardSubmit(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.CardSubmit, isRetry });
  },

  userSpendFirstCredit(isRetry: boolean = false) {
    this.sendEvent({
      eventName: GtmConversionEventName.SpendFirstCredit,
      isRetry,
    });
  },

  userSpend15Credits(isRetry: boolean = false) {
    this.sendEvent({
      eventName: GtmConversionEventName.Spend15Credits,
      isRetry,
    });
  },

  engagedUserV1(isRetry: boolean = false) {
    this.sendEvent({
      eventName: GtmConversionEventName.EngagedUserV1,
      isRetry,
    });
  },

  engagedUserRetention(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.EURetention, isRetry });
  },

  engagedUser30min(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.EU30min, isRetry });
  },

  engagedUserSubmit(isRetry: boolean = false) {
    this.sendEvent({ eventName: GtmConversionEventName.EUSubmit, isRetry });
  },

  mapEventNameToEventType(eventName: GtmConversionEventName) {
    switch (eventName) {
      case GtmConversionEventName.Registration: {
        return ConversionEventType.Registration;
      }
      case GtmConversionEventName.MailConfirm: {
        return ConversionEventType.MailConfirm;
      }
      case GtmConversionEventName.Sale: {
        return ConversionEventType.FirstPurchase;
      }
      case GtmConversionEventName.Resale: {
        return ConversionEventType.PurchaseTotal;
      }
      case GtmConversionEventName.CardSubmit: {
        return ConversionEventType.FindLocation;
      }
      case GtmConversionEventName.SpendFirstCredit: {
        return ConversionEventType.UserSpent1cr;
      }
      case GtmConversionEventName.Spend15Credits: {
        return ConversionEventType.UserSpent15cr;
      }
      case GtmConversionEventName.EngagedUserV1: {
        return ConversionEventType.EngagedUserV1;
      }
      case GtmConversionEventName.EURetention: {
        return ConversionEventType.EURetention;
      }
      case GtmConversionEventName.EU30min: {
        return ConversionEventType.EU30min;
      }
      case GtmConversionEventName.EUSubmit: {
        return ConversionEventType.EUSubmit;
      }

      default:
        return null;
    }
  },

  trackConversionEventByName(eventData: any) {
    const eventName = eventData?.event;

    if (!MirrorService.gtmId) return;

    switch (eventName) {
      case GtmConversionEventName.Registration: {
        this.userRegEvent();

        break;
      }
      case GtmConversionEventName.MailConfirm: {
        this.userMailConfirm();

        break;
      }
      case GtmConversionEventName.Sale: {
        this.userSale();

        break;
      }
      case GtmConversionEventName.Resale: {
        this.userResale();

        break;
      }
      case GtmConversionEventName.CardSubmit: {
        this.userCardSubmit();

        break;
      }
      case GtmConversionEventName.SpendFirstCredit: {
        this.userSpendFirstCredit();

        break;
      }
      case GtmConversionEventName.Spend15Credits: {
        this.userSpend15Credits();

        break;
      }
      case GtmConversionEventName.EngagedUserV1: {
        this.engagedUserV1();

        break;
      }
      case GtmConversionEventName.EURetention: {
        this.engagedUserRetention();

        break;
      }
      case GtmConversionEventName.EU30min: {
        this.engagedUser30min();

        break;
      }
      case GtmConversionEventName.EUSubmit: {
        this.engagedUserSubmit();

        break;
      }

      default:
        break;
    }
  },

  trackConversionEventByType(payload: {
    eventType: ConversionEventType;
    isRetry: boolean;
  }) {
    if (!MirrorService.gtmId) return;

    switch (payload.eventType) {
      case ConversionEventType.Registration: {
        this.userRegEvent(payload.isRetry);

        break;
      }

      case ConversionEventType.EngagedUserV1: {
        this.engagedUserV1(payload.isRetry);

        break;
      }
      case ConversionEventType.EURetention: {
        this.engagedUserRetention(payload.isRetry);

        break;
      }
      case ConversionEventType.EU30min: {
        this.engagedUser30min(payload.isRetry);

        break;
      }
      case ConversionEventType.EUSubmit: {
        this.engagedUserSubmit(payload.isRetry);

        break;
      }

      default:
        break;
    }
  },
};
