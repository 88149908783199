import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import { Pages } from 'types/enums/Pages';

import { useMedia } from 'hooks/useMedia';

import { AppRoute } from 'components/shared/AppRoute';
import { PrivateRoute } from 'components/shared/PrivateRoute';
import { PublicRoute } from 'components/shared/PublicRoute';

const SettingsMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsMobile" */ 'components/sections/MyProfile/Settings/SettingsMobile'
    ),
  {
    resolveComponent: (component) => component.SettingsMobile,
  }
);

const NotificationsSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationsSettings" */ 'components/sections/MyProfile/Settings/NotificationsSettings'
    ),
  {
    resolveComponent: (component) => component.NotificationsSettings,
  }
);

const DeleteAccount = loadable(
  () =>
    import(
      /* webpackChunkName: "DeleteAccount" */ 'components/sections/MyProfile/Settings/DeleteAccount'
    ),
  {
    resolveComponent: (component) => component.DeleteAccount,
  }
);

const ChangePassword = loadable(
  () =>
    import(
      /* webpackChunkName: "ChangePassword" */ 'components/sections/MyProfile/Settings/ChangePassword'
    ),
  {
    resolveComponent: (component) => component.ChangePassword,
  }
);

const ChangeEmail = loadable(
  () =>
    import(
      /* webpackChunkName: "ChangeEmail" */ 'components/sections/MyProfile/Settings/ChangeEmail'
    ),
  {
    resolveComponent: (component) => component.ChangeEmail,
  }
);

const AccountSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "AccountSettings" */ 'components/sections/MyProfile/Settings/AccountSettings'
    ),
  {
    resolveComponent: (component) => component.AccountSettings,
  }
);

const BlockedAndHiddenProfile = loadable(
  () =>
    import(
      /* webpackChunkName: "BlockedProfile" */ 'components/sections/MyProfile/BlockAndHide/BlockedAndHiddenPage'
    ),
  {
    resolveComponent: (component) => component.BlockedAndHiddenPage,
  }
);

const BlockedProfile = loadable(
  () =>
    import(
      /* webpackChunkName: "BlockedProfile" */ 'components/sections/MyProfile/BlockAndHide/BlockedProfile'
    ),
  {
    resolveComponent: (component) => component.BlockedProfile,
  }
);

const HiddenDialogs = loadable(
  () =>
    import(
      /* webpackChunkName: "HiddenDialogs" */ 'components/sections/MyProfile/BlockAndHide/HiddenDialogs'
    ),
  {
    resolveComponent: (component) => component.HiddenDialogs,
  }
);

const Pricing = loadable(
  () =>
    import(
      /* webpackChunkName: "Pricing" */ 'components/sections/MyProfile/Pricing'
    ),
  {
    resolveComponent: (component) => component.Pricing,
  }
);

const HelpCenter = loadable(
  () =>
    import(
      /* webpackChunkName: "HelpCenter" */ 'components/sections/MyProfile/HelpCenter'
    ),
  {
    resolveComponent: (component) => component.HelpCenter,
  }
);

const ContactUs = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUs" */ 'components/sections/MyProfile/ContactUs'
    ),
  {
    resolveComponent: (component) => component.ContactUs,
  }
);

const LandingRoutes = loadable(
  () => import(/* webpackChunkName: "LandingRoutes" */ 'pages/LandingRoutes'),
  {
    resolveComponent: (component) => component.LandingRoutes,
  }
);

const WizardRoutes = loadable(
  () => import(/* webpackChunkName: "WizardRoutes" */ 'pages/WizardRoutes'),
  {
    resolveComponent: (component) => component.WizardRoutes,
  }
);

const CheckInboxPage = loadable(
  () => import(/* webpackChunkName: "CheckInboxPage" */ 'pages/CheckInboxPage'),
  {
    resolveComponent: (component) => component.CheckInboxPage,
  }
);

const ForgotPasswordPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordPage" */ 'pages/ForgotPasswordPage'
    ),
  {
    resolveComponent: (component) => component.ForgotPasswordPage,
  }
);

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFoundPage'),
  {
    resolveComponent: (component) => component.NotFoundPage,
  }
);

const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ 'pages/ChatPage'),
  {
    resolveComponent: (component) => component.ChatPage,
  }
);

const NewPasswordPage = loadable(
  () =>
    import(/* webpackChunkName: "NewPasswordPage" */ 'pages/NewPasswordPage'),
  {
    resolveComponent: (component) => component.NewPasswordPage,
  }
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ 'pages/LegalPage'),
  {
    resolveComponent: (component) => component.LegalPage,
  }
);

const UserProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "UserProfilePage" */ 'pages/UserProfilePage'),
  {
    resolveComponent: (component) => component.UserProfilePage,
  }
);

const EditProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "EditProfilePage" */ 'pages/EditProfilePage'),
  {
    resolveComponent: (component) => component.EditProfilePage,
  }
);

const FinderPage = loadable(
  () => import(/* webpackChunkName: "FinderPage" */ 'pages/FinderPage'),
  {
    resolveComponent: (component) => component.FinderPage,
  }
);

const MessengerPage = loadable(
  () => import(/* webpackChunkName: "MessengerPage" */ 'pages/MessengerPage'),
  {
    resolveComponent: (component) => component.MessengerPage,
  }
);

const PresentsGuidePage = loadable(
  () =>
    import(
      /* webpackChunkName: "PresentsGuidePage" */ 'pages/PresentsGuidePage'
    ),
  {
    resolveComponent: (component) => component.PresentsGuidePage,
  }
);

const PresentsPage = loadable(
  () => import(/* webpackChunkName: "PresentsPage" */ 'pages/PresentsPage'),
  {
    resolveComponent: (component) => component.PresentsPage,
  }
);

const MailsChainsPagePage = loadable(
  () =>
    import(/* webpackChunkName: "MailsChatsPage" */ 'pages/MailsChainsPage'),
  {
    resolveComponent: (component) => component.MailsChatsPage,
  }
);

const MailsChatPage = loadable(
  () => import(/* webpackChunkName: "MailsChatPage" */ 'pages/MailsChatPage'),
  {
    resolveComponent: (component) => component.MailsChatPage,
  }
);

const MyProfilePage = loadable(
  () => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'),
  {
    resolveComponent: (component) => component.MyProfilePage,
  }
);

const UpgradeBalancePage = loadable(
  () =>
    import(
      /* webpackChunkName: "UpgradeBalancePage" */ 'pages/UpgradeBalancePage'
    ),
  {
    resolveComponent: (component) => component.UpgradeBalancePage,
  }
);

const UpgradeSubscriptionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "UpgradeSubscriptionPage" */ 'pages/UpgradeSubscriptionPage'
    ),
  {
    resolveComponent: (component) => component.UpgradeSubscriptionPage,
  }
);

const PaymentPage = loadable(
  () => import(/* webpackChunkName: "PaymentPage" */ 'pages/PaymentPage'),
  {
    resolveComponent: (component) => component.PaymentPage,
  }
);

const PaymentOneClickPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PaymentOneClickPage" */ 'pages/PaymentOneClickPage'
    ),
  {
    resolveComponent: (component) => component.PaymentOneClickPage,
  }
);

const PaymentSuccessPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PaymentSuccessPage" */ 'pages/PaymentSuccessPage'
    ),
  {
    resolveComponent: (component) => component.PaymentSuccessPage,
  }
);

const PaymentFailPage = loadable(
  () =>
    import(/* webpackChunkName: "PaymentFailPage" */ 'pages/PaymentFailPage'),
  {
    resolveComponent: (component) => component.PaymentFailPage,
  }
);

const LoginPage = loadable(
  () => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage'),
  {
    resolveComponent: (component) => component.LoginPage,
  }
);

const IndexPage = loadable(
  () => import(/* webpackChunkName: "IndexPage" */ 'pages/IndexPage'),
  {
    resolveComponent: (component) => component.IndexPage,
  }
);

const DataPrivacyPage = loadable(
  () =>
    import(/* webpackChunkName: "DataPrivacyPage" */ 'pages/DataPrivacyPage'),
  {
    resolveComponent: (components) => components.DataPrivacyPage,
  }
);

const FullscreenPhotoPage = loadable(
  () =>
    import(
      /* webpackChunkName: "FullscreenPhotoPage" */ 'pages/FullscreenPhotoPage'
    ),
  {
    resolveComponent: (component) => component.FullscreenPhotoPage,
  }
);

export const AppRouter = () => {
  const { isMobile } = useMedia();

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route index element={<AppRoute component={IndexPage} />} />
        <Route path={Pages.Landings} element={<LandingRoutes />} />
        <Route
          path={Pages.Login}
          element={<AppRoute component={LoginPage} />}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path={Pages.Wizards} element={<WizardRoutes />} />
        <Route
          path={Pages.Finder}
          element={<AppRoute component={FinderPage} />}
        />
        <Route
          path={Pages.Dialogs}
          element={<AppRoute component={MessengerPage} />}
        />
        <Route
          path={Pages.DialogsTread}
          element={<AppRoute component={isMobile ? ChatPage : MessengerPage} />}
        />
        <Route
          path={Pages.Mails}
          element={<AppRoute component={MailsChainsPagePage} />}
        />
        <Route
          path={Pages.MailsTread}
          element={<AppRoute component={MailsChatPage} />}
        />
        <Route
          path={Pages.UpgradeBalance}
          element={<AppRoute component={UpgradeBalancePage} />}
        />
        <Route
          path={Pages.UpgradeSubscription}
          element={<AppRoute component={UpgradeSubscriptionPage} />}
        />
        <Route
          path={Pages.PaymentByCard}
          element={<AppRoute component={PaymentPage} />}
        />
        <Route
          path={Pages.PaymentByOneClick}
          element={<AppRoute component={PaymentOneClickPage} />}
        />
        <Route
          path={Pages.FullMediaScreen}
          element={<AppRoute component={FullscreenPhotoPage} />}
        />
        <Route
          path={Pages.UserProfile}
          element={<AppRoute component={UserProfilePage} />}
        />
        <Route
          path={Pages.MyProfile}
          element={<AppRoute component={MyProfilePage} />}
        />
        <Route
          path={Pages.EditProfile}
          element={<AppRoute component={EditProfilePage} />}
        />
        <Route
          path={Pages.ContactUs}
          element={<AppRoute component={ContactUs} />}
        />
        <Route
          path={Pages.Pricing}
          element={<AppRoute component={Pricing} />}
        />
        <Route
          path={Pages.HelpCenter}
          element={<AppRoute component={HelpCenter} />}
        />
        <Route
          path={Pages.Settings}
          element={<AppRoute component={SettingsMobile} />}
        />
        <Route
          path={Pages.AccountSettings}
          element={<AppRoute component={AccountSettings} />}
        />
        <Route
          path={Pages.ChangeEmailSettings}
          element={<AppRoute component={ChangeEmail} />}
        />
        <Route
          path={Pages.ChangePasswordSettings}
          element={<AppRoute component={ChangePassword} />}
        />
        <Route
          path={Pages.NotificationsSettings}
          element={<AppRoute component={NotificationsSettings} />}
        />
        <Route
          path={Pages.AccountDeleteSettings}
          element={<AppRoute component={DeleteAccount} />}
        />
        <Route
          path={Pages.BlockedProfiles}
          element={<AppRoute component={BlockedProfile} />}
        />
        <Route
          path={Pages.BlockedAndHidden}
          element={<AppRoute component={BlockedAndHiddenProfile} />}
        />
        <Route
          path={Pages.HiddenDialogs}
          element={<AppRoute component={HiddenDialogs} />}
        />

        <Route
          path={Pages.PresentsGuide}
          element={<AppRoute component={PresentsGuidePage} />}
        />
        <Route
          path={Pages.PresentsSend}
          element={<AppRoute component={PresentsPage} />}
        />
      </Route>

      <Route
        path={Pages.CheckInbox}
        element={<AppRoute component={CheckInboxPage} />}
      />
      <Route
        path={Pages.DataPrivacy}
        element={<AppRoute component={DataPrivacyPage} />}
      />
      <Route
        path={Pages.ForgotPassword}
        element={<AppRoute component={ForgotPasswordPage} />}
      />
      <Route
        path={Pages.NewPassword}
        element={<AppRoute component={NewPasswordPage} />}
      />
      <Route
        path={Pages.PaymentFail}
        element={<AppRoute component={PaymentFailPage} />}
      />
      <Route
        path={Pages.PaymentSuccess}
        element={<AppRoute component={PaymentSuccessPage} />}
      />

      <Route element={<AppRoute component={LegalPage} />}>
        <Route path={Pages.AntiScamPolicy} element={null} />
        <Route path={Pages.DisclosuresDisclaimers} element={null} />
        <Route path={Pages.PrivacyPolicy} element={null} />
        <Route path={Pages.TrackingPolicy} element={null} />
        <Route path={Pages.Terms} element={null} />
        <Route path={Pages.RefundPolicy} element={null} />
      </Route>

      <Route path="*" element={<AppRoute component={NotFoundPage} />} />
    </Routes>
  );
};
