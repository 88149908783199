import { AppConfig, getApiUrl } from 'config';
import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

let appConfig: AppConfig;

export class MirrorService {
  static init(hostname: string = document.location.hostname) {
    appConfig = new AppConfig(hostname);
    appConfig = {
      ...appConfig,
      apiUrl:
        appConfig.id === 1 ? getApiUrl(appConfig.apiUrl) : appConfig.apiUrl,
      originForInitialId: getApiUrl(appConfig.originForInitialId),
    };

    return appConfig;
  }

  static getInstance() {
    if (!appConfig) {
      this.init();
    }

    return appConfig;
  }

  static get isProd() {
    const instance = this.getInstance();
    return instance.env === AppEnv.Prod;
  }

  static isCurrentSite(domain: AppDomain) {
    const instance = this.getInstance();
    return domain === instance.domain;
  }

  static get groupId() {
    const instance = this.getInstance();
    return instance.groupId;
  }

  static get domain() {
    const instance = this.getInstance();
    return instance.domain;
  }

  static get domainWithoutSubdomain() {
    const instance = this.getInstance();
    return instance.siteDomainWithoutSubdomain;
  }

  static get apiUrl() {
    const instance = this.getInstance();
    return instance.apiUrl;
  }

  static get serviceApiUrl() {
    const instance = this.getInstance();
    return instance.originForServiceDomain;
  }

  static get wsUrl() {
    const instance = this.getInstance();
    return instance.wsUrl;
  }

  static get originForInitialId() {
    const instance = this.getInstance();
    return instance.originForInitialId;
  }

  static get originForTranslations() {
    const instance = this.getInstance();
    return instance.originForTranslations;
  }

  static get supportEmail() {
    const instance = this.getInstance();
    return instance.supportEmail;
  }

  static get supportPhone() {
    const instance = this.getInstance();
    return instance.supportPhone;
  }

  static get siteName() {
    const instance = this.getInstance();
    return instance.sitename;
  }

  static get gtmId() {
    const instance = this.getInstance();
    return instance.gtmId;
  }

  static get cookieCbid() {
    const instance = this.getInstance();
    return instance.cookieCbid;
  }

  static get clarityId() {
    const instance = this.getInstance();
    return instance.clarityId;
  }

  static get isMariaDates() {
    return this.isCurrentSite(AppDomain.MariaDates);
  }

  static get isPlaceForTalk() {
    return this.isCurrentSite(AppDomain.PlaceForTalk);
  }

  static get isLatinEsse() {
    return this.isCurrentSite(AppDomain.LatinEsse);
  }

  static get isEmberLove() {
    return this.isCurrentSite(AppDomain.EmberLove);
  }

  static get isSlavicSite() {
    return this.isPlaceForTalk || this.isEmberLove || this.isMariaDates;
  }

  static get isRSite() {
    return this.groupId === 1;
  }

  static get isTSite() {
    return this.groupId === 2;
  }

  static resolveImagePath = (imagePath?: string | null) => {
    if (!imagePath) return '';

    if (imagePath.includes('https') || imagePath.includes('static/media'))
      return imagePath;

    return `https://images.${this.domainWithoutSubdomain}${imagePath}`;
  };

  static get descriptor() {
    const instance = this.getInstance();
    return instance.descriptor;
  }
}
