import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { TrackingApi } from 'api/TrackingApi';

export const usePopupTracking = () => {
  const [popupFlowId, setPopupFlowId] = useState<string>(() => uuidv4());

  const trackPopup = useCallback(
    ({
      popupName,
      operation,
      clickDestination,
    }: {
      popupName: PopupName;
      operation: PopupOperation;
      clickDestination?: string;
    }) => {
      TrackingApi.trackPopup({
        id: popupFlowId,
        name: popupName,
        operation,
        clickDestination,
      }).catch((err) => err);

      if (operation === PopupOperation.Close) {
        setPopupFlowId(uuidv4());
      }
    },
    [popupFlowId]
  );

  const trackPopupAsync = useCallback(
    async ({
      popupName,
      operation,
      clickDestination,
    }: {
      popupName: PopupName;
      operation: PopupOperation;
      clickDestination?: string;
    }) => {
      await TrackingApi.trackPopup({
        id: popupFlowId,
        name: popupName,
        operation,
        clickDestination,
      }).catch((err) => err);

      if (operation === PopupOperation.Close) {
        setPopupFlowId(uuidv4());
      }
    },
    [popupFlowId]
  );

  return {
    trackPopup,
    trackPopupAsync,
  };
};
