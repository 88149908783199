import React, { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TrackingApi } from 'api/TrackingApi';
import { useAppMeta } from 'hooks/useAppMeta';
import { useAuthInterceptors } from 'hooks/useAuthInterceptors';
import { useClickIdAssign } from 'hooks/useClickIdAssign';
import { useCookieSettings } from 'hooks/useCookieSettings';
import { useDeviceRotation } from 'hooks/useDeviceRotation';
import { useEuPing } from 'hooks/useEuPing';
import { useInitializeApp } from 'hooks/useInitializeApp';
import { useMirrorThemeColors } from 'hooks/useMirrorTheme';
import { useOnlineOnClickPopup } from 'hooks/useOnlineOnClickPopup';
import { useOnlinePingV2 } from 'hooks/useOnlinePingV2';
import { usePageLoad } from 'hooks/usePageLoad';
import { usePopupsQueue } from 'hooks/usePopupsQueue';
import { useRetryConversions } from 'hooks/useRetryConversions';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import { useStarterPopups } from 'hooks/useStarterPopups';
import { useUpdateBodyHeightOnResize } from 'hooks/useUpdateBodyHeightOnResize';
import { useXPrevUrl } from 'hooks/useXPrevUrl';
import { useXRefererUrl } from 'hooks/useXRefererUrl';
import { getIsAuthenticated, getUserId } from 'store/auth/selectors';
import { setPrevPagePathname } from 'store/common/commonSlice';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';

import { MailConfirmationNotification } from 'components/sections/MailConfirmation/MailConfirmationNotification';
import { SystemPopup } from 'components/sections/SystemPopups';
import { FullscreenSpinner } from 'components/shared/FullscreenSpinner';
import { ZendeskWidget } from 'components/shared/ZendeskWidget';

import { AppRouter } from './AppRouter';

export const App = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const userId = useSelector(getUserId);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);

  useAuthInterceptors();
  useClickIdAssign();
  useXPrevUrl();
  useXRefererUrl();

  const { isInitialized } = useInitializeApp();

  useEuPing(!!isAuthenticated);
  useOnlinePingV2(!!isAuthenticated);
  useOnlineOnClickPopup();
  useStarterPopups();
  usePopupsQueue();
  useCookieSettings(!!isAuthenticated);
  useRetryConversions(!!isAuthenticated);

  usePageLoad();
  useAppMeta();
  useMirrorThemeColors();
  useUpdateBodyHeightOnResize();
  useScrollRestoration();
  useDeviceRotation();

  useEffect(() => {
    if (userId && clarity.hasStarted()) {
      clarity.identify('USER_ID', { userProperty: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (isInitialized) TrackingApi.trackAppLoadEnd();
  }, [isInitialized]);

  useEffect(() => {
    return () => {
      dispatch(setPrevPagePathname(pathname));
    };
  }, [dispatch, pathname]);

  if (!isInitialized) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <ZendeskWidget />

      <MailConfirmationNotification />
      {activeSystemPopupType && <SystemPopup />}

      <AppRouter />
    </>
  );
};
