import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatDialogTab } from 'types/enums/chat/ChatDialogTab';
import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { ChatMessageType } from 'types/enums/chat/ChatMessageType';
import { ChatDialogHideStatus } from 'types/enums/ChatDialogHideStatus';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatStickerPack } from 'types/interfaces/chat/ChatStickerPack';
import { Photo } from 'types/interfaces/Photo';
import { UserContact } from 'types/interfaces/user/UserContact';

import {
  assertGiftMessage,
  assertPhotoMessage,
} from 'helpers/asserts/messages';

interface Dialogs {
  data: ChatDialog[];
  next: string | null;
}

interface ChatRequests {
  data: ChatDialog[];
  next: string | null;
  total: number;
}

const defaultDIalogsFilters = {
  search: '',
  tab: ChatDialogTab.All,
  isOnline: false,
};

type MessengerState = {
  dialogs: Dialogs;
  dialogsFilters: ChatDialogFilters;
  dialogsLoading: boolean;

  chatRequests: ChatRequests;
  isChatRequestsLoading: boolean;
  isChatRequestsFirstLoaded: boolean;

  messages: Record<
    string,
    {
      next: string | null;
      isLiked: boolean;
      isEnabledPresents: boolean;
      contact: UserContact;
      dialog: ChatDialog;
      messages: ChatMessage[];
    }
  >;
  messagesLoading: boolean;

  gifts: ChatGift[];
  giftsLoading: boolean;

  stickerPacks: ChatStickerPack[];
  stickerPacksLoading: boolean;

  typingContacts: string[];
  initTypingContacts: string[];
};

const initialState: MessengerState = {
  dialogs: {
    data: [],
    next: null,
  },
  dialogsFilters: defaultDIalogsFilters,
  dialogsLoading: true,

  chatRequests: {
    data: [],
    next: null,
    total: 0,
  },
  isChatRequestsLoading: true,
  isChatRequestsFirstLoaded: false,

  messages: {},
  messagesLoading: true,

  gifts: [],
  giftsLoading: true,

  stickerPacks: [],
  stickerPacksLoading: true,

  typingContacts: [],
  initTypingContacts: [],
};

const updateDialogWithNewMessageMapper =
  (newMessage: ChatMessage, contactId: string) => (dialog: ChatDialog) => {
    const isDialogWithProperContact = dialog.contact?.ulid_id === contactId;

    if (!isDialogWithProperContact) {
      return dialog;
    }

    return {
      ...dialog,
      unread_count: newMessage.is_incoming ? dialog.unread_count + 1 : 0,
      contact_unread_count: !newMessage.is_incoming
        ? dialog.unread_count + 1
        : 0,
      last_message_type: newMessage.type,
      last_message_body: newMessage.body || null,
      last_message_format: newMessage.format,
      last_message_sent_at: newMessage.sent_at,
      last_message_status: newMessage.status,
    };
  };

const setMessageRead = () => (message: ChatMessage) => {
  if (message.is_incoming || message.status === ChatMessageStatus.Failed) {
    return message;
  }

  return {
    ...message,
    status: ChatMessageStatus.Read,
  };
};

const getUniqueDialogs = (dialogs: ChatDialog[]) => {
  const uniqueIds: Record<string, boolean> = {};

  return dialogs.filter((dialogItem) => {
    if (uniqueIds[`${dialogItem.contact.ulid_id}-${dialogItem.user.ulid_id}`]) {
      return false;
    }

    uniqueIds[`${dialogItem.contact.ulid_id}-${dialogItem.user.ulid_id}`] =
      true;
    return true;
  });
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    // ? ***************** DIALOGS ACTIONS START *****************
    setDialogs(state, action: PayloadAction<Dialogs>) {
      state.dialogs = action.payload;
    },

    setDialogsLoading(state, action: PayloadAction<boolean>) {
      state.dialogsLoading = action.payload;
    },

    addDialog(state, action: PayloadAction<ChatDialog>) {
      state.dialogs.data = [action.payload].concat(state.dialogs.data);
    },

    addDialogs(state, action: PayloadAction<Dialogs>) {
      state.dialogs = {
        data: getUniqueDialogs([...state.dialogs.data, ...action.payload.data]),
        next: action.payload.next,
      };
    },

    setDialogsFilters(state, action: PayloadAction<ChatDialogFilters>) {
      state.dialogsFilters = action.payload;
    },

    resetDialogsFilters(state) {
      state.dialogsFilters = defaultDIalogsFilters;
    },

    updateDialogWithNewMessage(
      state,
      action: PayloadAction<{ message: ChatMessage; contactId: string }>
    ) {
      const { message: newMessage, contactId } = action.payload;

      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialog) => dialog.contact?.ulid_id === contactId
        )
      );

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map(
          updateDialogWithNewMessageMapper(newMessage, contactId)
        );
      }
    },

    updateDialogStatus(
      state,
      action: PayloadAction<{ contactId: string; isIncoming: boolean }>
    ) {
      const { contactId, isIncoming } = action.payload;

      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialog) => dialog.contact?.ulid_id === contactId
        )
      );

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map((dialog) => {
          if (dialog.contact?.ulid_id === contactId) {
            return isIncoming
              ? { ...dialog, unread_count: 0 }
              : { ...dialog, contact_unread_count: 0 };
          }
          return dialog;
        });
      }
    },

    removeDialog(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;

      state.dialogs.data = state.dialogs.data.filter(
        (dialogItem) => dialogItem.contact?.ulid_id !== contactId
      );
    },

    hideDialog(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;

      state.dialogs.data = state.dialogs.data.filter(
        (dialogItem) => dialogItem.contact?.ulid_id !== contactId
      );
    },

    unHideDialog(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;
      const dialogFilters = state.dialogsFilters;

      if (dialogFilters.tab === ChatDialogTab.Hidden) {
        state.dialogs.data = state.dialogs.data.filter(
          (dialogItem) => dialogItem.contact?.ulid_id !== contactId
        );
      }

      state.messages[contactId].dialog.is_hide = ChatDialogHideStatus.Shown;
    },

    updateLatMessagesIncomingStatus(
      state,
      action: PayloadAction<{
        contactId: string;
        isIncoming: number;
      }>
    ) {
      const { contactId, isIncoming } = action.payload;

      state.dialogs.data = state.dialogs.data.map((dialog) => {
        if (dialog.contact?.ulid_id === contactId) {
          return { ...dialog, is_last_message_incoming: isIncoming };
        }
        return dialog;
      });
    },
    // ? ***************** DIALOGS ACTIONS END *****************

    // ? ***************** CHAT REQUESTS ACTIONS START *****************
    setChatRequests(state, action: PayloadAction<ChatRequests>) {
      state.chatRequests = action.payload;
    },

    setChatRequestsLoading(state, action: PayloadAction<boolean>) {
      state.isChatRequestsLoading = action.payload;
    },
    setChatRequestsFirstLoaded(state) {
      state.isChatRequestsFirstLoaded = true;
    },

    addChatRequests(state, action: PayloadAction<ChatRequests>) {
      state.chatRequests = {
        data: state.chatRequests.data.concat(action.payload.data),
        next: action.payload.next,
        total: action.payload.total || state.chatRequests.total,
      };
    },

    addChatRequest(state, action: PayloadAction<ChatDialog>) {
      state.chatRequests.data = [action.payload].concat(
        state.chatRequests.data
      );
    },

    removeChatRequest(state, action: PayloadAction<{ contactId: string }>) {
      const isExistInChatRequests = Boolean(
        state.chatRequests.data.find(
          (dialog) => dialog.contact?.ulid_id === action.payload?.contactId
        )
      );

      if (isExistInChatRequests) {
        state.chatRequests.data = state.chatRequests.data.filter(
          (requestItem) =>
            requestItem.contact?.ulid_id !== action.payload?.contactId
        );

        state.chatRequests.total -= 1;
      }
    },

    updateChatRequestWithNewMessage(
      state,
      action: PayloadAction<{ message: ChatMessage; contactId: string }>
    ) {
      const { message: newMessage, contactId } = action.payload;

      const isExistInRequests = Boolean(
        state.chatRequests.data.find(
          (dialog) => dialog.contact.ulid_id === contactId
        )
      );

      if (isExistInRequests) {
        state.chatRequests.data = state.chatRequests.data.map(
          updateDialogWithNewMessageMapper(newMessage, contactId)
        );
      }
    },

    updateChatRequestReadStatus(
      state,
      action: PayloadAction<{ contactId: string; isIncoming: boolean }>
    ) {
      const { contactId, isIncoming } = action.payload;

      const isExistInChatRequests = Boolean(
        state.chatRequests.data.find(
          (chatRequest) => chatRequest.contact?.ulid_id === contactId
        )
      );

      if (isExistInChatRequests) {
        state.chatRequests.data = state.chatRequests.data.map((chatRequest) => {
          if (chatRequest.contact.ulid_id === contactId) {
            return isIncoming
              ? {
                  ...chatRequest,
                  unread_count: 0,
                  last_message_status: ChatMessageStatus.Read,
                }
              : {
                  ...chatRequest,
                  contact_unread_count: 0,
                  last_message_status: ChatMessageStatus.Read,
                };
          }
          return chatRequest;
        });
      }
    },

    // ? ***************** CHAT REQUESTS ACTIONS END *****************

    // ? ***************** MESSAGES ACTIONS *****************
    setMessages(
      state,
      action: PayloadAction<{
        contactId: string;
        isLiked: boolean;
        isEnabledPresents: boolean;
        next: string | null;
        contact: UserContact;
        dialog: ChatDialog;
        messages: ChatMessage[];
      }>
    ) {
      const {
        messages,
        contact,
        contactId,
        dialog,
        isLiked,
        isEnabledPresents,
        next,
      } = action.payload;

      state.messages[contactId] = {
        next,
        isLiked,
        isEnabledPresents,
        contact,
        dialog,
        messages,
      };
    },

    setMessagesLoading(state, action: PayloadAction<boolean>) {
      state.messagesLoading = action.payload;
    },

    addMessage(
      state,
      action: PayloadAction<{ message: ChatMessage; contactId: string }>
    ) {
      const { message, contactId } = action.payload;

      if (state.messages[contactId] && state.messages[contactId].messages) {
        let isMessageAlreadyExist = false;

        const newMessages = state.messages[contactId].messages.map(
          (messageItem) => {
            if (
              (message.id && messageItem.id === message.id) ||
              (messageItem.front_message_id &&
                message.front_message_id &&
                messageItem.front_message_id === message.front_message_id)
            ) {
              isMessageAlreadyExist = true;

              return message;
            }
            return messageItem;
          }
        );

        state.messages[contactId].messages = isMessageAlreadyExist
          ? newMessages
          : [...newMessages, message];
      } else {
        state.messages[contactId] = {
          ...state.messages[contactId],
          messages: [message],
          next: null,
        };
      }
    },

    updateMessage(
      state,
      action: PayloadAction<{ message: ChatMessage; contactId: string }>
    ) {
      const { message, contactId } = action.payload;

      if (state.messages[contactId] && state.messages[contactId].messages) {
        state.messages[contactId].messages = state.messages[
          contactId
        ].messages.map((oldMessage) => {
          return oldMessage.id === message.id ? message : oldMessage;
        });
      } else {
        state.messages[contactId] = {
          ...state.messages[contactId],
          messages: [message],
          next: null,
        };
      }
    },

    removeDisappearingMessages(
      state,
      action: PayloadAction<{ contactId: string }>
    ) {
      const { contactId } = action.payload;

      if (state.messages[contactId] && state.messages[contactId].messages) {
        state.messages[contactId].messages = state.messages[
          contactId
        ].messages.map((oldMessage) => {
          if (oldMessage.type === ChatMessageType.Disappearing) {
            return {
              ...oldMessage,
              delete_at: null,
              type: ChatMessageType.General,
            };
          }

          return oldMessage;
        });
      }

      if (state.dialogs.data)
        state.dialogs.data = state.dialogs.data.map((dialogItem) => {
          if (
            dialogItem.contact?.ulid_id === contactId &&
            dialogItem.last_message_type === ChatMessageType.Disappearing
          ) {
            return {
              ...dialogItem,
              last_message_type: ChatMessageType.General,
            };
          }

          return dialogItem;
        });

      if (state.chatRequests.data)
        state.chatRequests.data = state.chatRequests.data.map(
          (chatRequestItem) => {
            if (
              chatRequestItem.contact?.ulid_id === contactId &&
              chatRequestItem.last_message_type === ChatMessageType.Disappearing
            ) {
              return {
                ...chatRequestItem,
                last_message_type: ChatMessageType.General,
              };
            }

            return chatRequestItem;
          }
        );
    },

    removeDisappearingMessageWithDialog(
      state,
      action: PayloadAction<{ contactId: string; messageId: string }>
    ) {
      const { contactId, messageId } = action.payload;

      if (state.messages[contactId] && state.messages[contactId].messages) {
        state.messages[contactId].messages = state.messages[
          contactId
        ].messages.filter((message) => message.id !== messageId);
      }

      if (state.dialogs.data)
        state.dialogs.data = state.dialogs.data.filter(
          (dialogItem) => dialogItem.contact.ulid_id !== contactId
        );
    },

    addMessages(
      state,
      action: PayloadAction<{
        messages: ChatMessage[];
        contactId: string;
        next: string | null;
      }>
    ) {
      const { messages, contactId, next } = action.payload;

      const allMessages = state.messages[contactId].messages.concat(messages);

      state.messages[contactId] = {
        ...state.messages[contactId],
        messages: allMessages,
        next,
      };
    },

    markMessagesAsRead(state, action: PayloadAction<{ contactId: string }>) {
      const {
        payload: { contactId },
      } = action;

      if (!state.messages[contactId] || !state.messages[contactId].messages) {
        return;
      }

      state.messages[contactId].messages = state.messages[
        contactId
      ].messages.map(setMessageRead());
    },

    updateMessagesPhotoSources(
      state,
      action: PayloadAction<{
        contactId: string;
        photos: Photo[];
      }>
    ) {
      const { contactId, photos } = action.payload;

      const allMessages = state.messages?.[contactId]?.messages;

      if (!allMessages) return;

      state.messages[contactId].messages = allMessages.map((message) => {
        if (assertPhotoMessage(message)) {
          const newPhoto = photos.find(
            (photoItem) => photoItem.id === message.photo?.id
          );

          if (newPhoto)
            return {
              ...message,
              photo: {
                ...message.photo,
                ...newPhoto,
              },
            };
        }

        return message;
      });
    },

    openGift(
      state,
      action: PayloadAction<{
        contactId: string;
        messageId: string;
      }>
    ) {
      const { contactId, messageId } = action.payload;

      const allMessages = state.messages?.[contactId]?.messages;

      if (!allMessages) return;

      state.messages[contactId].messages = allMessages.map((message) => {
        if (assertGiftMessage(message) && message?.id === messageId) {
          return {
            ...message,
            is_gift_opened: true,
          };
        }

        return message;
      });
    },

    // ? ***************** MESSAGES ACTIONS *****************

    // ? ***************** CONTACT ACTIONS *****************
    likeChatContact(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;

      if (state.messages[contactId]) state.messages[contactId].isLiked = true;
    },
    // ? ***************** CONTACT ACTIONS *****************

    // ? ***************** GIFTS ACTIONS *****************
    setGifts(state, action: PayloadAction<ChatGift[]>) {
      state.gifts = action.payload;
    },
    setGiftsLoading(state, action: PayloadAction<boolean>) {
      state.giftsLoading = action.payload;
    },
    // updateGift(state, action: PayloadAction<Partial<ChatGift>>) {},
    // ? ***************** GIFTS ACTIONS *****************

    // ? ***************** STICKERS ACTIONS *****************
    setStickerPacks(state, action: PayloadAction<ChatStickerPack[]>) {
      state.stickerPacks = action.payload;
    },
    setStickerPacksLoading(state, action: PayloadAction<boolean>) {
      state.stickerPacksLoading = action.payload;
    },
    // ? ***************** STICKERS ACTIONS *****************

    addTypingContact(state, action: PayloadAction<{ contactId: string }>) {
      const {
        payload: { contactId },
      } = action;

      const alreadyTyping = state.typingContacts.includes(contactId);

      if (!alreadyTyping)
        state.typingContacts = [contactId, ...state.typingContacts];
    },

    removeTypingContact(state, action: PayloadAction<{ contactId: string }>) {
      const {
        payload: { contactId },
      } = action;

      state.typingContacts = state.typingContacts.filter(
        (id) => id !== contactId
      );
    },

    addInitTypingContact(state, action: PayloadAction<{ contactId: string }>) {
      const {
        payload: { contactId },
      } = action;

      const alreadyTyping = state.initTypingContacts.includes(contactId);

      if (!alreadyTyping)
        state.initTypingContacts = [contactId, ...state.initTypingContacts];
    },

    removeInitTypingContact(
      state,
      action: PayloadAction<{ contactId: string }>
    ) {
      const {
        payload: { contactId },
      } = action;

      state.initTypingContacts = state.initTypingContacts.filter(
        (id) => id !== contactId
      );
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setDialogs,
  setDialogsLoading,
  addDialogs,
  addDialog,
  updateDialogWithNewMessage,
  updateDialogStatus,
  removeDialog,
  hideDialog,
  unHideDialog,
  updateLatMessagesIncomingStatus,

  setDialogsFilters,
  resetDialogsFilters,

  setChatRequests,
  setChatRequestsLoading,
  setChatRequestsFirstLoaded,
  addChatRequests,
  addChatRequest,
  removeChatRequest,
  updateChatRequestWithNewMessage,
  updateChatRequestReadStatus,

  setMessages,
  addMessage,
  updateMessage,
  removeDisappearingMessages,
  removeDisappearingMessageWithDialog,
  addMessages,
  markMessagesAsRead,
  setMessagesLoading,
  updateMessagesPhotoSources,
  openGift,

  likeChatContact,

  setGifts,
  setGiftsLoading,

  setStickerPacks,
  setStickerPacksLoading,

  addTypingContact,
  removeTypingContact,

  addInitTypingContact,
  removeInitTypingContact,

  resetState,
} = messengerSlice.actions;

export const messenger = messengerSlice.reducer;
